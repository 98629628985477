import React, {useState, useContext, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { signIn, confirmSignIn } from 'aws-amplify/auth';
import PasswordStrengthIndicator from '../../components/passwordStrengthIndicator';
import { useSnackbar } from 'notistack';
import { ProductContext } from '../../ProductProvider';
import Preloader from '../../components/Preloader/Preloader';
import logo from '../../images/logo/logo-new.png';
import { RoutesLocal } from '../../routes';
import {Helmet} from 'react-helmet-async';

const isNumberRegx = /\d/;
const specialCharacterRegx = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
const capsRegx=/[A-Z ]+/;
const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export default function Login() {
   const { validateLogin, loginValidated, setLoginValidated, loggedUser } = useContext(ProductContext);
    const { enqueueSnackbar } = useSnackbar();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showNewPass, setShowNewPass] = useState(false);
    const [newPassword, setNewPassword]=useState('');
    const [loaded, setLoaded] = useState(true);
    const [passwordValidity, setPasswordValidity] = useState({
        minChar: null,
        number: null,
        specialChar: null,
        capsChar:null
      });
      const [passwordFocused, setPasswordFocused] = useState(false);
      const [newpasswordFocused, setNewPasswordFocused] = useState(false);
      const [newpasswordValidity, setNewPasswordValidity] = useState({
        minChar: null,
        number: null,
        specialChar: null,
        capsChar:null
      });
    
      useEffect(() => {
        if(!loggedUser.isAuthenticated && !loginValidated){
          setLoginValidated(true);
          validateLogin();
        }
      }, [])
    

const handleLogin = async(event) => {
    event.preventDefault();
    try{
        setLoaded(false);
        setNewPasswordFocused(false);
        if(username===""||password===""){
          enqueueSnackbar("Username and Password cannot be empty",{variant:'info'});
          setLoaded(true);
        }else if(!username.match(validRegex)){
          enqueueSnackbar("Enter your registered eamil address!",{variant:'info'});
        }else{
     await signIn({ 
      username, 
      password,
      })
     .then(async(user)=>{
      //console.log("User" , user);
      if(!user.isSignedIn && user.nextStep.signInStep==="CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED"){
        //setUser(user);
        setPasswordFocused(false);
        setShowNewPass(true);
        enqueueSnackbar("Set up your New Password",{variant:'info'});
      }else{  
        await validateLogin();
     }
     setLoaded(true);
    })
     .catch((error)=>{
      //console.log("Error :", error);
      setLoaded(true);
      enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
     })
  }
      }catch(error){
        //console.log("Error", error);
        setLoaded(true);
        enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});            
      }
  };
  
  const handleChangePassword =async(event)=>{
    event.preventDefault();
    try{
      setLoaded(false);
      const attributes = {
        email: username,
       };
       await confirmSignIn({
        challengeResponse: newPassword,
       })
      .then(async(user) => {
        //console.log(user);
        if(user.isSignedIn){
          await validateLogin();
          enqueueSnackbar("Authenticated Successfully!",{variant:'success'});
          setLoaded(true);    
        }
        //setSidebarStatus("Orginal");
    }).catch(e => {
      setLoaded(true);
      enqueueSnackbar("Error Occured - " + e.message,{variant : 'warning'});
    });
    }catch(error){
      setLoaded(true);
      enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
    }
  }
  
  const onChangePassword = password => {
    setPassword(password);
    setPasswordValidity({
        minChar: password.length >= 8 ? true : false,
        number: isNumberRegx.test(password) ? true : false,
        specialChar: specialCharacterRegx.test(password) ? true : false,
        capsChar:capsRegx.test(password)?true:false
    });
  };
  
  const onNewChangePassword = password => {
    setNewPassword(password);
    setNewPasswordValidity({
      minChar: password.length >= 8 ? true : false,
      number: isNumberRegx.test(password) ? true : false,
      specialChar: specialCharacterRegx.test(password) ? true : false,
      capsChar:capsRegx.test(password)?true:false
    });
  };
  
  return (
    <div>
    <Helmet>
      <title>Sign In - Admin Office - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION</title>
      <meta name="description" content="Sign In - Admin Office - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION" />
      <meta name="keywords" content="Sign In - Admin Office - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION" /> 
    </Helmet>

         {!loaded &&  <Preloader show={loaded ? false : true} /> }
        <div className="full_container">
         <div className="container">
            <div className="center verticle_center full_height">
               <div className="login_section">
                  <div className="logo_login">
                     <div className="center">
                        <img width="210" src={logo} alt="#" />
                     </div>
                     {/*VIDYAJYOTI JOURNAL - Admin Office*/}
                     <h3 className="center">Admin Office - VIDYAJYOTI JOURNAL</h3>
                  </div>
                  <h2 className="center">Sign In</h2>
                  <div className="login_form">
                     <form>
                        <fieldset>
                           <div className="field">
                              <label className="label_field">Email Address</label>
                              <input type="email" name="email" placeholder="E-mail"
                              onChange={event=>setUsername(event.target.value)} value={username} />
                           </div>
                           {!showNewPass?<div className="field">
                              <label className="label_field">Password</label>
                              <input type="password" name="password" placeholder="Password"
                              onFocus={() => setPasswordFocused(true)}
                              onChange={e =>
                                  onChangePassword(e.target.value)}            
                                          value={password} />
                           </div>:<div className="field">
                              <label className="label_field">New Password</label>
                              <input type="password" name="password" placeholder="Password"
                              onFocus={() => setNewPasswordFocused(true)}
                              onChange={e =>
                                  onNewChangePassword(e.target.value)}            
                            value={newPassword} />
                           </div>}
                           {passwordFocused && (
                                    <PasswordStrengthIndicator
                                        validity={passwordValidity}
                                    />
                                )}
                            {/*newpasswordFocused && (
                                    <PasswordStrengthIndicator
                                        validity={newpasswordValidity}
                                    />
                                )*/}
                           <div className="">
                              {/*<label className="label_field hidden">hidden label</label>
                              <label className="form-check-label"><input type="checkbox" className="form-check-input"/> Remember Me</label>*/}
                              <a className="forgot" href="#"><Link to={RoutesLocal.ForgotPassword.path}>Forgotten Password?</Link></a>{"       "}
                              <a className="forgot" href="#"><Link to={RoutesLocal.Signup.path}>Sign Up</Link></a> {"       "}
                           </div>
                           {/*<div className="field">
                              <label className="label_field hidden">hidden label</label>
                              <a className="forgot" href="#"><Link to={RoutesLocal.Signup.path}>Sign Up</Link></a>
                           </div>*/}
                           <div className="field margin_0">
                              <label className="label_field hidden">hidden label</label>
                              {!showNewPass?<button className="main_bt" onClick={event=>handleLogin(event)}>Sign In</button>:
                              <button className="main_bt" onClick={event=>handleChangePassword(event)}>Change Password</button>}
                           </div>
                        </fieldset>
                     </form>
                  </div>
               </div>
            </div>
         </div>
      </div>
    </div>
  )
}
