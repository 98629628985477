import React, { useEffect, useState } from 'react'
import { expenseCategory } from '../../ProData';
import { Row, Card } from 'react-bootstrap';
import { Chart } from "react-google-charts";

export default function ExpCategoryBreakUp(props) {
    const {currentExpCategory, newTitle}=props;
    const [displayExpCategory, setDisplayExpCategory] = useState([]);

    useEffect(()=>{
        if(JSON.stringify(currentExpCategory) !== '{}'){
            processData();
        }
    },[currentExpCategory])

    const options = {
        title:newTitle,
        pieHole: 0.4,
        is3D: false,
        legend: {
            position: "bottom",
            alignment: "center",
            textStyle: {
              color: "#233238",
              fontSize: 12,
            },
          },
      };

    const processData=()=>{
        const tempArray=[];
        var subChartHeader=[];
        const chartHearder=["Category","Amount"];
        subChartHeader.push(chartHearder);

        for (const i in expenseCategory){
            var testJson={};
            if(currentExpCategory[expenseCategory[i].categoryName].length>0){
            const test=currentExpCategory[expenseCategory[i].categoryName];
           var sum=0;
           test.forEach(x => {
            sum += parseFloat(x.expenseAmount);
        });

            testJson[expenseCategory[i].categoryName]=sum;
            tempArray.push(testJson);
            const ttt=[expenseCategory[i].categoryName, sum];
            subChartHeader.push(ttt);    
        }else{
            testJson[expenseCategory[i].categoryName]=0;
            tempArray.push(testJson);   
            const ttt=[expenseCategory[i].categoryName, 0];
            subChartHeader.push(ttt);
     
        }
        }
       // setDisplayExpCategory(tempArray);
       // console.log("Test Json: ", subChartHeader);
        //tempArray.unshift(chartHearder);
        //console.log("arrary : ", tempArray);
        //processSubscriberData(tempArray, "processSubscriberData");
        setDisplayExpCategory(subChartHeader);
    }

    /*const processSubscriberData=(rawData, type)=>{
        for(const data in rawData){
            const ttt=[data, rawData[data]];
            subChartHeader.push(ttt);
        }
        console.log("subChartHeader", subChartHeader);
        if(type==="Subscriber"){
            setDisplayExpCategory(subChartHeader);
        //const tactiveData=subChartHeader.filter(item=>item[0]!=="Cancelled");
        //setGoogleActiveSubData(tactiveData);
        }
        else{
        //setGoogleCatData(subChartHeader);
        }
    }*/

  return (
    <div>
    {displayExpCategory ? <Card><Row className="justify-content-md-center">
        <Chart
        chartType="PieChart"
        width="100%"
        height="400px"
        data={displayExpCategory }
        options={options}
        />
    </Row></Card>:null}
    </div>
  )
}
