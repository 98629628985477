import React, { useEffect, useContext, useState } from 'react';
import { useSnackbar } from 'notistack';
import { ProductContext } from '../../ProductProvider';
import logo from '../../images/logo/logo-new.png';
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown, Table } from 'react-bootstrap';
import { expenseCategory } from '../../ProData';

export default function ViewProfile() {
    const { loggedUser, validateLogin} = useContext(ProductContext);
    const [loaded, setLoaded] = useState(false);
    const [viewOnly, setViewOnly] = useState(true);
    const [subscriberDetails, setsubscriberDetails] = useState({subscriberID:'', title:'Mr',subscriberName:'', areaCode:'+91',contactNo:'', emailID:'', houseName:'', addressStreet:'', addressCity:'', addressState:'',
    addressPincode:'', country:'', copies:'', categoryID:'',subStatus:'', regDate:'', startDate:'', endDate:''});
    const { enqueueSnackbar } = useSnackbar();

    useEffect(()=>{
        if(loggedUser.isAuthenticated){
          setViewOnly(true);
         // fetchMyProfile();
        }else{
          validateLogin();
        }
      },[loggedUser])

      {/*const fetchMyProfile=async()=>{
        setLoaded(false);
        try{
         const { idToken } = (await fetchAuthSession()).tokens ?? {};
              const searchOption="SubscriberID";
              await axios.get(`${config.api.invokeUrl}/subscribers/globalsearch?fetchKey=${loggedUser.nickname}&fetchOption=${searchOption}`, {
                headers: {
                  Authorization: idToken,
                  'x-api-key':config.api.key
                }
              }).then(async(response) => {
               //console.log("response : ", response.data[0]);
                if(response.data.length>0)
                {
                    setsubscriberDetails(response.data[0]);
                    //setProfileDetails(response.data[0]);
                    enqueueSnackbar("Profile loaded Successfully!",{variant : 'success'});
                  setLoaded(true);
                }
                setLoaded(true);
            }).catch((error)=>{
               enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
            setLoaded(true);
          })
    
        }catch(error){
         enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
        setLoaded(true);
    
      }
    }*/}
    
 
  return (
    <div>
        <div className="midde_cont">
                  <div className="container-fluid">
                     <div className="row column_title">
                        <div className="col-md-12">
                           <div className="page_title">
                              <h2>Profile</h2>
                           </div>
                        </div>
                     </div>
                    {/*} <!-- row -->*/}
                     <div className="row column1">
                        <div className="col-md-2"></div>
                        <div className="col-md-8">
                           <div className="white_shd full margin_bottom_30">
                              <div className="full graph_head">
                                 <div className="heading1 margin_0">
                                    <h2>User profile</h2>
                                 </div>
                              </div>
                              <div className="full price_table padding_infor_info">
                                 <div className="row">
                                    {/*<!-- user profile section --> 
                                    <!-- profile image -->*/}
                                    <div className="col-lg-12">
                                       <div className="full dis_flex center_text">
                                          <div className="profile_img"><img width="180" className="rounded-circle" src={logo} alt="#" /></div>
                                          <div className="profile_contant">
                                             <div className="contact_inner">
                                                <h3>{loggedUser.name}</h3>
                                                <p><strong>Profile: </strong>{loggedUser.profile}</p>
                                                <ul className="list-unstyled">
                                                   <li><i className="fa fa-envelope-o"></i> : {loggedUser.email}</li>
                                                   <li><i className="fa fa-phone"></i> : {loggedUser.phone_number}</li>
                                                </ul>
                                             </div>
                                             <div className="user_progress_bar">
                                                <div className="progress_bar">
                                                  {/*} <!-- Skill Bars -->*/}
                                                   <span className="skill" style={{width:"85%"}}>Profile <span className="info_valume">85%</span></span>                   
                                                   <div className="progress skill-bar ">
                                                      <div className="progress-bar progress-bar-animated progress-bar-striped" role="progressbar" aria-valuenow="85" aria-valuemin="0" aria-valuemax="100" style={{width: "85%"}}>
                                                      </div>
                                                   </div>
                                                  {/*} <span className="skill" style={{width:"78%"}}>Website Design <span className="info_valume">78%</span></span>   
                                                   <div className="progress skill-bar">
                                                      <div className="progress-bar progress-bar-animated progress-bar-striped" role="progressbar" aria-valuenow="78" aria-valuemin="0" aria-valuemax="100" style={{width: "78%"}}>
                                                      </div>
                                                   </div>
                                                   <span className="skill" style={{width:"47%"}}>Automation & Testing <span className="info_valume">47%</span></span>
                                                   <div className="progress skill-bar">
                                                      <div className="progress-bar progress-bar-animated progress-bar-striped" role="progressbar" aria-valuenow="54" aria-valuemin="0" aria-valuemax="100" style={{width: "54%"}}>
                                                      </div>
                                                   </div>
                                                   <span className="skill" style={{width:"65%"}}>UI / UX <span className="info_valume">65%</span></span>
                                                   <div className="progress skill-bar">
                                                      <div className="progress-bar progress-bar-animated progress-bar-striped" role="progressbar" aria-valuenow="65" aria-valuemin="0" aria-valuemax="100" style={{width: "65%"}}>
                                                      </div>
                                                   </div>*/}
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       {/*<!-- profile contant section -->*/}
                                       <div className="full inner_elements margin_top_30">
                                          <div className="tab_style2">
                                             <div className="tabbar">
                                                <nav>
                                                   <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                                      <a className="nav-item nav-link active" id="nav-home-tab" data-bs-toggle="tab" href="#recent_activity" role="tab" aria-selected="true">Expense Category</a>
                                                      <a className="nav-item nav-link" id="nav-profile-tab" data-bs-toggle="tab" href="#project_worked" role="tab" aria-selected="false">Income Category</a>
                                                      <a className="nav-item nav-link" id="nav-contact-tab" data-bs-toggle="tab" href="#profile_section" role="tab" aria-selected="false">Profile</a>
                                                   </div>
                                                </nav>
                                                <div className="tab-content" id="nav-tabContent">
                                                   <div className="tab-pane fade show active" id="recent_activity" role="tabpanel" aria-labelledby="nav-home-tab">
                                                      <div className="msg_list_main">

                                                      <div className="table-settings mb-4">
                                                            <Row className="justify-content-start align-items-center">
                                                            <Col  className="mb-3" md={8}>
                                                                <InputGroup>
                                                                <InputGroup.Text>
                                                                <i className="fa fa-search" aria-hidden="true"></i>
                                                                </InputGroup.Text>

                                                                <Form.Control type="text"  autoFocus placeholder="Search" />
                                                                <Button variant="primary" type="submit" >Add</Button>
                                                                </InputGroup>
                                                            </Col>
                                                            </Row>
                                                        </div>
                                                         <ul className="msg_list">
                                                            <li>
                                                               <span><img src="images/layout_img/msg2.png" className="img-responsive" alt="#"/></span>
                                                               <span>
                                                               <span className="name_user">Taison Jack</span>
                                                               <span className="msg_user">Sed ut perspiciatis unde omnis.</span>
                                                               <span className="time_ago">12 min ago</span>
                                                               </span>
                                                            </li>
                                                            <li>
                                                               <span><img src="images/layout_img/msg3.png" className="img-responsive" alt="#"/></span>
                                                               <span>
                                                               <span className="name_user">Mike John</span>
                                                               <span className="msg_user">On the other hand, we denounce.</span>
                                                               <span className="time_ago">12 min ago</span>
                                                               </span>
                                                            </li>
                                                         </ul>
                                                      </div>
                                                   </div>
                                                   <div className="tab-pane fade" id="project_worked" role="tabpanel" aria-labelledby="nav-profile-tab">
                                                      <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et 
                                                         quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos 
                                                         qui ratione voluptatem sequi nesciunt.
                                                      </p>
                                                   </div>
                                                   <div className="tab-pane fade" id="profile_section" role="tabpanel" aria-labelledby="nav-contact-tab">
                                                      <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et 
                                                         quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos 
                                                         qui ratione voluptatem sequi nesciunt.
                                                      </p>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="col-md-2"></div>
                        </div>
                     </div>
                   
                  </div>
               </div>
    </div>
  )
}
