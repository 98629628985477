import React, {useState, useContext, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { signUp, confirmSignUp } from 'aws-amplify/auth';
import PasswordStrengthIndicator from '../../components/passwordStrengthIndicator';
import { useSnackbar } from 'notistack';
import { ProductContext } from '../../ProductProvider';
import Preloader from '../../components/Preloader/Preloader';
import logo from '../../images/logo/logo-new.png';
import { RoutesLocal } from '../../routes';
import {Helmet} from 'react-helmet-async';

const isNumberRegx = /\d/;
const specialCharacterRegx = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
const capsRegx=/[A-Z ]+/;
const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export default function SignUp() {
   const { validateLogin, loginValidated, setLoginValidated, loggedUser } = useContext(ProductContext);
    const { enqueueSnackbar } = useSnackbar();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showNewPass, setShowNewPass] = useState(false);
    const [newPassword, setNewPassword]=useState('');
    const [loaded, setLoaded] = useState(true);
    const [passwordValidity, setPasswordValidity] = useState({
        minChar: null,
        number: null,
        specialChar: null,
        capsChar:null
      });
      const [passwordFocused, setPasswordFocused] = useState(false);
      const [fullName, setFullName] = useState('');
      const [dateOfBirth, setDateOfBirth] = useState('');
      useEffect(() => {
        if(!loggedUser.isAuthenticated && !loginValidated){
          setLoginValidated(true);
          validateLogin();
        }
      }, [])
    

const handleSignUp = async(event) => {
    event.preventDefault();
    try{
        setLoaded(false);
        if(username===""||password===""){
          enqueueSnackbar("Email and Password cannot be empty",{variant:'info'});
          setLoaded(true);
        }else if(!username.match(validRegex)){
          enqueueSnackbar("Enter valid email address!",{variant:'info'});
        }else{
            const todayDate=(new Date().getTime() / 1000).toFixed(0).toString();
        await signUp({
        username, 
        password,
        options: {
            userAttributes: {
              email: username,
              phone_number: "+15555555555",
              profile:"Owner",
              address:"abc",
              birthdate:dateOfBirth,
              gender:"Male",
              preferred_username:"abc",
              updated_at:todayDate,
              website:"abc",
              picture:"abc",
              zoneinfo:"abc",
              locale:"aBC",
              given_name:"abc",
              family_name:"abc",
              middle_name:"abc",
              name:fullName,
              nickname:"2301221206" // E.164 number convention
            },
          }        
      })
     .then(async(user)=>{
      console.log("User" , user);
      if(!user.isSignUpComplete && user.nextStep.signUpStep==="CONFIRM_SIGN_UP"){
        //setUser(user);
        setPasswordFocused(false);
        setShowNewPass(true);
        enqueueSnackbar("Enter your One Time Password",{variant:'info'});
      }else{  
        await validateLogin();
     }
     setLoaded(true);
    })
     .catch((error)=>{
      console.log("Error :", error);
      setLoaded(true);
      enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
     })
  }
      }catch(error){
        console.log("Error", error);
        setLoaded(true);
        enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});            
      }
  };
  
  const handleOTPValidation =async(event)=>{
    event.preventDefault();
    try{
      setLoaded(false);
       await confirmSignUp({
        username:username,
        confirmationCode: newPassword,
       })
      .then(async(user) => {
        console.log(user);
        if(user.isSignUpComplete && user.nextStep.signUpStep==="DONE"){
            enqueueSnackbar("Your registration is Successfull!",{variant:'success'});
          await validateLogin();
          setLoaded(true);    
        }
        setLoaded(true);  
        //setSidebarStatus("Orginal");
    }).catch(e => {
      setLoaded(true);
      enqueueSnackbar("Error Occured - " + e.message,{variant : 'warning'});
    });
    }catch(error){
      setLoaded(true);
      enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
    }
  }
  
  const onChangePassword = password => {
    setPassword(password);
    setPasswordValidity({
        minChar: password.length >= 8 ? true : false,
        number: isNumberRegx.test(password) ? true : false,
        specialChar: specialCharacterRegx.test(password) ? true : false,
        capsChar:capsRegx.test(password)?true:false
    });
  };
  
  
  return (
    <div>
    <Helmet>
      <title>Sign Up - Admin Office - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION</title>
      <meta name="description" content="Sign In - Admin Office - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION" />
      <meta name="keywords" content="Sign In - Admin Office - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION" /> 
    </Helmet>

         {!loaded &&  <Preloader show={loaded ? false : true} /> }
        <div className="full_container">
         <div className="container">
            <div className="center verticle_center full_height">
               <div className="login_section">
                  <div className="logo_login">
                      {/*<div className="center">
                        <img width="210" src={logo} alt="#" />
                     </div>
                    VIDYAJYOTI JOURNAL - Admin Office
                     <h3 className="center">Sign Up - My Buddy Finance Assistant</h3>*/}
                  <h2 className="center">Sign Up</h2>
                  <p>Complete the registration form to set up your account.</p>

                  </div>
                  <div className="login_form">
                     <form>
                        <fieldset>
                        <div className="field">
                              <label className="label_field">Enter Full Name</label>
                              <input type="text" name="name" placeholder="Enter Name"
                              onChange={event=>setFullName(event.target.value)} value={fullName} />
                           </div>
                           <div className="field">
                              <label className="label_field">Date Of Birth</label>
                              <input type="date" name="dob" 
                              onChange={event=>setDateOfBirth(event.target.value)} value={dateOfBirth} />
                           </div>
                           <div className="field">
                              <label className="label_field">Email Address</label>
                              <input type="email" name="email" placeholder="E-mail"
                              onChange={event=>setUsername(event.target.value)} value={username} />
                           </div>
                           {!showNewPass?<div className="field">
                              <label className="label_field">Password</label>
                              <input type="password" name="password" placeholder="Password"
                              onFocus={() => setPasswordFocused(true)}
                              onChange={e =>
                                  onChangePassword(e.target.value)}            
                                          value={password} />
                           </div>:<div className="field">
                              <label className="label_field">Enter OTP</label>
                              <input type="password" name="newpassword" placeholder="OTP"
                              onChange={e =>
                                setNewPassword(e.target.value)}            
                            value={newPassword} />
                           </div>}
                           {passwordFocused && (
                                    <PasswordStrengthIndicator
                                        validity={passwordValidity}
                                    />
                                )}
                           <div className="field">
                              <label className="label_field hidden">hidden label</label>
                              {/*<label className="form-check-label"><input type="checkbox" className="form-check-input"/> Remember Me</label>*/}
                              <a className="forgot" href="#"><Link to={RoutesLocal.ForgotPassword.path}>Forgotten Password?</Link></a>
                              <a className="forgot" href="#"><Link to={RoutesLocal.Signup.path}>Sign In</Link></a>
                           </div>
                           <div className="field margin_0">
                              <label className="label_field hidden">hidden label</label>
                              {!showNewPass?<button className="main_bt" onClick={event=>handleSignUp(event)}>Sign Up</button>:
                              <button className="main_bt" onClick={event=>handleOTPValidation(event)}>Confirm OTP</button>}
                           </div>
                        </fieldset>
                     </form>
                  </div>
               </div>
            </div>
         </div>
      </div>
    </div>
  )
}
