import React, { useEffect, useState } from 'react'
import { paymentCategory } from '../../ProData';
import { Row, Card } from 'react-bootstrap';
import { Chart } from "react-google-charts";

export default function PaymentModeBreakUp(props) {
    const {currentExpDetails, newTitle}=props;
    const [displayExpCategory, setDisplayExpCategory] = useState([]);

    useEffect(()=>{
        //console.log(currentExpCategory);
        if(JSON.stringify(currentExpDetails) !== '{}'){
            processCurrentPaymentData();
        }
    },[currentExpDetails])

    const options = {
        title:newTitle,
        pieHole: 0.4,
        is3D: false,
        legend: {
            position: "bottom",
            alignment: "center",
            textStyle: {
              color: "#233238",
              fontSize: 14,
            },
          },
      };

      const processCurrentPaymentData=async(res, pastRes)=>{
        const tempArray=[];
        //console.log("Ex Category :", expenseCategory);
        const tempJson={};
      
        for (const i in paymentCategory){
         // console.log("Exp Category : ", expenseCategory[i].categoryName);
          const tempValue=currentExpDetails.filter((item)=>item.paymentMode===paymentCategory[i].paymentName);
          //console.log("temp value : ", tempValue);
          tempJson[paymentCategory[i].paymentName]=tempValue;
          //tempArray.push(tempJson);
        }
      
        //setExpDetails(tempArray);
        //console.log("temp tempJson :", tempJson);
        //setCurrentExpCategory(tempJson);
        processData(tempJson)
        }

    const processData=(currentExpCategory)=>{
        const tempArray=[];
        var subChartHeader=[];
        const chartHearder=["Category","Amount"];
        subChartHeader.push(chartHearder);

        for (const i in paymentCategory){
            var testJson={};
            if(currentExpCategory[paymentCategory[i].paymentName].length>0){
            const test=currentExpCategory[paymentCategory[i].paymentName];
           var sum=0;
           test.forEach(x => {
            sum += parseFloat(x.expenseAmount);
        });

            testJson[paymentCategory[i].paymentName]=sum;
            tempArray.push(testJson);
            const ttt=[paymentCategory[i].paymentName, sum];
            subChartHeader.push(ttt);    
        }else{
            testJson[paymentCategory[i].paymentName]=0;
            tempArray.push(testJson);   
            const ttt=[paymentCategory[i].paymentName, 0];
            subChartHeader.push(ttt);
     
        }
        }
       // setDisplayExpCategory(tempArray);
       // console.log("Test Json: ", subChartHeader);
        //tempArray.unshift(chartHearder);
        //console.log("arrary : ", tempArray);
        //processSubscriberData(tempArray, "processSubscriberData");
        setDisplayExpCategory(subChartHeader);
    }

  return (
    <div>
    {displayExpCategory ? <Card><Row className="justify-content-md-center">
        <Chart
        chartType="PieChart"
        width="100%"
        height="400px"
        data={displayExpCategory }
        options={options}
        />
    </Row></Card>:null}
    </div>
  )
}
