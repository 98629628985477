import React, { useEffect, useState } from 'react';
import { Card, Table } from 'react-bootstrap';
export default function TendingExpenses(props) {

    const {currentExpDetails} = props;
    const [sortedExp, setSortedExp]= useState([]);

    useEffect(()=>{
        processData();
    },[currentExpDetails])

    const processData=()=>{
        const tempsort=currentExpDetails.sort((a,b)=>b.expenseAmount-a.expenseAmount);
        //console.log("Sort : ", tempsort);
        const limitedData = tempsort.filter((item, index) =>index < 10).map(item=>item);
        setSortedExp(limitedData);

    }

  return (
    <div>
        <h4>Top 10 Expenses</h4>
        <Card style={{ height: '400px' }}>
        {sortedExp.length>0 && <Table>
            <thead>
            <th>Date</th>
            <th>Category</th>
            <th>Payment Type</th>
            <th>Amount</th>
            </thead>
            <tbody>
            {sortedExp.map((item, index)=>
                <tr key={index}>
                <td>{item.expenseDate}</td>
                <td>{item.expenseCategory}</td>
                <td>{item.paymentMode}</td>
                <td>{item.expenseAmount}</td>
                </tr>
            )}
            </tbody>
        </Table>}</Card>
    </div>
  )
}
