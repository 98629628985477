import React,{useEffect, useContext, useState} from 'react'
import { ProductContext } from '../../ProductProvider';
import {Helmet} from 'react-helmet-async';
import { Modal, Button, Row, Col, Card, Breadcrumb } from 'react-bootstrap';
import ExpenseForm from '../expenses/ExpenseForm';
import AddExpButton from '../floatingButton/AddExpButton';
import { useSnackbar } from 'notistack';
import axios from "axios";
import { expenseCategory, incomeCategory } from '../../ProData';
import { GoogleDataTableColumnRoleType } from 'react-google-charts';
import TendingExpenses from './TendingExpenses';
import ExpCategoryBreakUp from './ExpCategoryBreakUp';
import PaymentModeBreakUp from './PaymentModeBreakUp';
const config = require('../../config.json');

export default function LandingPage() {
  const { loggedUser, validateLogin, expenseDetails, generateDate } = useContext(ProductContext);
  const [showAdd, setShowAdd] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [loaded, setLoaded] = useState(true);
  const [displayCurrentExpense, setDisplayCurrentExpense]=useState({totalExp:0,processed:false}); 
  const [displayPastExpense, setDisplayPastExpense]=useState({totalExp:0, processed:false}); 
  const [expPercentage,setExpPercentage] = useState(0);
  const [currentExpDetails, setCurrentExpDetails] = useState([]);
  const [pastExpDetails, setPastExpDetails] = useState([]);
  const [currentExpCategory, setCurrentExpCategory]= useState({});
  const [pastExpCategory, setPastExpCategory]= useState({});
  const [currentIncCategory, setCurrentIncCategory]= useState({});
  const [displayCurrentIncome, setDisplayCurrentIncome]=useState({totalInc:0,processed:false}); 
  const [incPercentage,setIncPercentage] = useState(0);
  const [displayPastIncome, setDisplayPastIncome]=useState({totalInc:0, processed:false}); 


  useEffect(()=>{
    if(!loggedUser.isAuthenticated){
      validateLogin();
    }else{
      fetchExpenseDetails(0,0,0,"Current");
      fetchIncomeDetails(0,0,0,"Current");
    }
  },[loggedUser])  

  const handleShowNewExp=(event)=>{
    event.preventDefault();
    setShowAdd(true);
    //console.log("Exp Details:", expenseDetails);
  }

  const handleCloseNewExp=()=>{
    setShowAdd(false);
  }

 const fetchExpenseDetails=async(yy,mm,dd,period)=>{
  try{
    //const { idToken } = (await fetchAuthSession()).tokens ?? {};
    const todayDate=generateDate(yy,mm,dd);
    //const tempDate=
    console.log("Nickname :", loggedUser.nickname);
    const searchValue=todayDate;
    const searchTopic="ExpeneseMonthReport";
    await axios.get(`${config.api.invokeUrl}/tracker/expense/reports?fetchKey=${searchValue}&fetchOption=${searchTopic}&clientID=${loggedUser.nickname}`, {
      headers: {
      //  Authorization: idToken,
        'x-api-key':config.api.key
      }
    }).then(async(response) => {
      console.log("response : ", response.data);
      if(response.data.length>0)
      {
          //console.log("order status")
          //setSearchResults(response.data);
            await setPastExpDetails(response.data[1]);
            setCurrentExpDetails(response.data[0]);
            processCurrentData(response.data[0], response.data[1]);
      }
    //  enqueueSnackbar(response.data.length + " - Payment records found!",{variant : 'success'});
      setLoaded(true);

  }).catch((error)=>{
    enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
    setLoaded(true);
  })
  }catch(error){
    enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
  }
 }

 const fetchIncomeDetails=async(yy,mm,dd,period)=>{
  try{
    //const { idToken } = (await fetchAuthSession()).tokens ?? {};
    const todayDate=generateDate(yy,mm,dd);
    //const tempDate=
    //console.log("Dte :", todayDate);
    const searchValue=todayDate;
    const searchTopic="IncomeMonthReport";
    await axios.get(`${config.api.invokeUrl}/tracker/income/reports?fetchKey=${searchValue}&fetchOption=${searchTopic}&clientID=${loggedUser.nickname}`, {
      headers: {
      //  Authorization: idToken,
        'x-api-key':config.api.key
      }
    }).then(async(response) => {
      console.log("response : ", response.data);
      if(response.data.length>0)
      {
            //await setPastIncDetails(response.data[1]);
            //setCurrentIncDetails(response.data[0]);
            processCurrentIncomeData(response.data[0], response.data[1]);
      }
      setLoaded(true);
  }).catch((error)=>{
    enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
    setLoaded(true);
  })
  }catch(error){
    enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
  }
 }

 const processCurrentIncomeData=async(res, pastRes)=>{
  const tempArray=[];
  //console.log("Ex Category :", expenseCategory);
  const tempJson={};
  for (const i in incomeCategory){
   // console.log("Exp Category : ", expenseCategory[i].categoryName);
    const tempValue=res.filter((item)=>item.incomeCategory===incomeCategory[i].categoryName);
    //console.log("temp value : ", tempValue);
    tempJson[incomeCategory[i].categoryName]=tempValue;
    //tempArray.push(tempJson);
  }

  //setExpDetails(tempArray);
  console.log("temp tempJson :", tempJson);
  setCurrentIncCategory(tempJson);
  var tempSum=0;
  for (const i in res){
    tempSum=tempSum+parseFloat(res[i].incomeAmount);
  }
  //console.log("temp Array :", tempSum);
  await setDisplayCurrentIncome({...displayCurrentIncome, 
    totalInc:tempSum,
    processed:true
  });
  processPastIncomeData(pastRes, tempSum);

 // fetchExpenseDetails(0,-1,0,"Past");
 }

 const processPastIncomeData=async(res, TotalCurrentExp)=>{
  const tempArray=[];
  //console.log("Ex Category :", expenseCategory);
  for (const i in incomeCategory){
    //console.log("Exp Category : ", expenseCategory[i].categoryName);
    const tempJson={};
    const tempValue=res.filter((item)=>item.incomeCategory===incomeCategory[i].categoryName);
    //console.log("temp value : ", tempValue);
    tempJson[incomeCategory[i].categoryName]=tempValue;
    tempArray.push(tempJson);
  }
  //console.log("temp Array :", tempArray);

  var tempSum=0;
  for (const i in res){
    tempSum=tempSum+parseFloat(res[i].incomeAmount);
  }
  //console.log("temp Array :", tempSum);
  await setDisplayPastIncome({...displayPastIncome, 
    totalExp:tempSum,
    processed:true
  });
  calculateDifference(TotalCurrentExp,tempSum,"Income");
 }



 const processCurrentData=async(res, pastRes)=>{
  const tempArray=[];
  //console.log("Ex Category :", expenseCategory);
  const tempJson={};

  for (const i in expenseCategory){
   // console.log("Exp Category : ", expenseCategory[i].categoryName);
    const tempValue=res.filter((item)=>item.expenseCategory===expenseCategory[i].categoryName);
    //console.log("temp value : ", tempValue);
    tempJson[expenseCategory[i].categoryName]=tempValue;
    //tempArray.push(tempJson);
  }

  //setExpDetails(tempArray);
  console.log("temp tempJson :", tempJson);
  setCurrentExpCategory(tempJson);
  var tempSum=0;
  for (const i in res){
    tempSum=tempSum+parseFloat(res[i].expenseAmount);
  }
  //console.log("temp Array :", tempSum);
  await setDisplayCurrentExpense({...displayCurrentExpense, 
    totalExp:tempSum,
    processed:true
  });
  processPastData(pastRes, tempSum);

 // fetchExpenseDetails(0,-1,0,"Past");
 }

 const processPastData=async(res, TotalCurrentExp)=>{
  const tempArray=[];
  //console.log("Ex Category :", expenseCategory);
  const tempJson={};
  for (const i in expenseCategory){
    //console.log("Exp Category : ", expenseCategory[i].categoryName);
    const tempValue=res.filter((item)=>item.expenseCategory===expenseCategory[i].categoryName);
    //console.log("temp value : ", tempValue);
    tempJson[expenseCategory[i].categoryName]=tempValue;
    tempArray.push(tempJson);
  }
  //console.log("temp Array :", tempArray);
  setPastExpCategory(tempJson);

  var tempSum=0;
  for (const i in res){
    tempSum=tempSum+parseFloat(res[i].expenseAmount);
  }
  //console.log("temp Array :", tempSum);
  await setDisplayPastExpense({...displayPastExpense, 
    totalExp:tempSum,
    processed:true
  });
  calculateDifference(TotalCurrentExp,tempSum,"Expense" );
 }

 const calculateDifference=(TotalCurrentExp, TotalPastExp, type)=>{
  const difference=TotalCurrentExp-TotalPastExp;
  const average=(TotalCurrentExp+TotalPastExp)/2;
  const percentage=((difference/average)*100).toFixed(2);
  if(type==="Income"){
    setIncPercentage(percentage);
  }else{
    setExpPercentage(percentage);
  }
 }

 const processCurrentPaymentData=async(res, pastRes)=>{
  const tempArray=[];
  //console.log("Ex Category :", expenseCategory);
  const tempJson={};

  for (const i in expenseCategory){
   // console.log("Exp Category : ", expenseCategory[i].categoryName);
    const tempValue=res.filter((item)=>item.expenseCategory===expenseCategory[i].categoryName);
    //console.log("temp value : ", tempValue);
    tempJson[expenseCategory[i].categoryName]=tempValue;
    //tempArray.push(tempJson);
  }

  //setExpDetails(tempArray);
  console.log("temp tempJson :", tempJson);
  setCurrentExpCategory(tempJson);
  var tempSum=0;
  for (const i in res){
    tempSum=tempSum+parseFloat(res[i].expenseAmount);
  }
  //console.log("temp Array :", tempSum);
  await setDisplayCurrentExpense({...displayCurrentExpense, 
    totalExp:tempSum,
    processed:true
  });
  processPastData(pastRes, tempSum);

 // fetchExpenseDetails(0,-1,0,"Past");
 }



  return (
    <div className="App">
    <Helmet>
      <title>Admin Office - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION</title>
      <meta name="description" content="Admin Office - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION" />
      <meta name="keywords" content="Admin Office - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION" /> 
    </Helmet>
    <Modal show={showAdd}
        onHide={handleCloseNewExp}>
    <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ExpenseForm handleCloseNewExp={handleCloseNewExp}/>
        </Modal.Body>
        {/*<Modal.Footer>
          <Button variant="secondary" onClick={handleCloseNewExp}>
            Close
          </Button>
          <Button variant="primary" onClick={handleCloseNewExp}>
            Save Changes
          </Button>
        </Modal.Footer>*/}
    </Modal>
    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><i className="fa fa-home" aria-hidden="true"></i>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item active>Dashboard</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Dashboard</h4>
          <p>Welcome {loggedUser.name} to My Finance Assistant!</p>
        </div>
        <AddExpButton handleShowNewExp={handleShowNewExp}/>
       {/*} <div className="btn-toolbar mb-2 mb-md-0"> 
          <ButtonGroup>
          <ReactToPrint
        content={reactToPrintContent}
        documentTitle="student"
        trigger={reactToPrintTrigger}
      />
          <Button variant="outline-primary" size="sm" onClick={(event)=>{handleDownload(event)}}>Download</Button>
          <CSVLink
              headers={downloadPaymentHeaders}
              filename="download.csv"
              data={searchResults}
              ref={csvLinkEl}
            />
          </ButtonGroup>
        </div>*/}
      </div>
      <Row>
      <Col md={4} className="mb-3">
        <Card>
          <Card.Body>
            <p>Total Income</p>
            <h4><i className="fa fa-inr" aria-hidden="true"></i>{displayCurrentIncome.totalInc}</h4>
            </Card.Body>
            <Card.Footer>
            {incPercentage<0?<i className="fa fa-arrow-down text-danger"></i>:<i className="fa fa-arrow-up text-success"></i>}<span>{incPercentage}% Last month <i className="fa fa-inr" aria-hidden="true"></i><span>{displayPastIncome.totalInc}</span></span>
            </Card.Footer>
        </Card>
        </Col>
        <Col md={4} className="mb-3">
        <Card>
          <Card.Body>
            <p>Total Expense</p>
            <h4><i className="fa fa-inr" aria-hidden="true"></i>{displayCurrentExpense.totalExp}</h4>
            </Card.Body>
            <Card.Footer>
            {expPercentage<0?<i className="fa fa-arrow-down text-success"></i>:<i className="fa fa-arrow-up text-danger"></i>}<span>{expPercentage}% Last month <i className="fa fa-inr" aria-hidden="true"></i><span>{displayPastExpense.totalExp}</span></span>
            </Card.Footer>
        </Card>
        </Col>
        <Col md={4} className="mb-3">
        <Card>
          <Card.Body>
            <p>Cash in Hand</p>
            <h4><i className="fa fa-inr" aria-hidden="true"></i>{parseFloat(displayCurrentIncome.totalInc)-parseFloat(displayCurrentExpense.totalExp)}</h4>
            </Card.Body>
            <Card.Footer>
            {(parseFloat(displayCurrentIncome.totalInc)-parseFloat(displayCurrentExpense.totalExp))>0?<i className="fa fa-arrow-up text-success"></i>:<i className="fa fa-arrow-up text-danger"></i>}
            </Card.Footer>
        </Card>
        </Col>
        {/*<Col md={3} className="mb-3">
        <Card>
          <Card.Body>
            <p>Total Income</p>
            <p>432568</p>
            </Card.Body>
            <Card.Footer>
            <i className="fa fa-arrow-trend-up"></i>2.47% Last month $24,478
            </Card.Footer>
        </Card>
        </Col>*/}
      </Row>
      <div>
        <Row>
          <Col>
          <TendingExpenses currentExpDetails={currentExpDetails}/>
          </Col>
          </Row>
          <div style={{paddingTop:"10px"}}>
        <Card>
        <h4>Expense Category comparison (Last month vs current month)</h4>
          <Row>
          <Col>
          <ExpCategoryBreakUp currentExpCategory={currentExpCategory} newTitle="Current Month"/>
          </Col>
          <Col>
          <ExpCategoryBreakUp currentExpCategory={pastExpCategory} newTitle="Last Month"/>
          </Col>
        </Row>
        </Card>
          </div>

        <div style={{paddingTop:"10px"}}>
        <Card>
        <h4>Payment Mode comparison (Last month vs current month)</h4>
        <Row>
          <Col xs={6}>
          <PaymentModeBreakUp currentExpDetails={currentExpDetails} newTitle="Current Month"/>
          </Col>
          <Col xs={6}>
          <PaymentModeBreakUp currentExpDetails={pastExpDetails} newTitle="Last Month"/>
          </Col>
          </Row>
          </Card>
          </div>
      </div>
    </div>
  )
}
