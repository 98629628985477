import React, { useEffect, useState, useContext, useRef } from "react";
import { Col, Row, Form, Button, InputGroup, Card, CardBody } from 'react-bootstrap';
import ReactToPrint from "react-to-print";
import { ButtonGroup, Breadcrumb } from 'react-bootstrap';
import { ProductContext } from '../../ProductProvider';
import Preloader from "../../components/Preloader/Preloader";
import { fetchAuthSession } from 'aws-amplify/auth';
import { expenseCategory, incomeCategory } from "../../ProData";
import axios from "axios";
import {Helmet} from 'react-helmet-async';
import { useSnackbar } from 'notistack';

const formType="New";
const config = require('../../config.json');

export default function IncomeForm(props) {
    const {formType}=props;
    const {loggedUser, generateOrderNo, generateDate, incomeDetails, setIncomeDetails, validateLogin} = useContext(ProductContext);
    const [loaded, setLoaded] = useState(true);
    const [viewOnly, setViewOnly] = useState(true);
    const [reoccuringEvent, setReoccuringEvent]=useState(false);
    const inputYears=useRef(null);
    const inputPaymentMode=useRef(null);
    const inputEndDate=useRef(null);
    const inputStartDate=useRef(null);
    const inputOrderAmount=useRef(null);
    const inputTransactionDate=useRef(null);
    const inputComments=useRef(null);
    const inputReferenceID=useRef(null);
    const inputCurrency=useRef(null);
    const inputBankName=useRef(null);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(()=>{
        //console.log("Props : ", props);
        if(!loggedUser.isAuthenticated){
            validateLogin();
          }else{      
        if(formType==="New"){
            //resetValues();
           // assignNewSubcriptionDates();
            assignDefaultValues();
            setViewOnly(false);
        }else if (formType==="View"){
            setViewOnly(true);
           // assignValues();
        }else if(formType==="Edit"){
            setViewOnly(false);
           // assignValues();
        }
    }
    },[])

    const assignDefaultValues=()=>{
        const todayDate=generateDate(0,0,0);
        console.log("Today :", todayDate);
        setIncomeDetails({...incomeDetails,
            incomeDate:todayDate,
            paymentMode:'Cash',
            incomeCategory:incomeCategory[0].categoryName,
            incomeID:'', incomeAmount:0, incomeSubCategory:''
        });
    }

    const validateFields=()=>{
        var validateResult="PASS";
        if(incomeDetails.paymentMode==="Cheque"){
            if(incomeDetails.referenceId===""){
                enqueueSnackbar("Enter Cheque Number!",{variant : 'info'});
                validateResult="FAIL";
            }else if(incomeDetails.bankName===""){
                enqueueSnackbar("Enter Bank Name!",{variant : 'info'});
                validateResult="FAIL";
            }
        } else if(incomeDetails.paymentMode==="DD"){
            if(incomeDetails.referenceId===""){
                enqueueSnackbar("Enter DD Number!",{variant : 'info'});
                validateResult="FAIL";
            }else if(incomeDetails.bankName===""){
                enqueueSnackbar("Enter Bank Name!",{variant : 'info'});
                validateResult="FAIL";
            }
        }else if(incomeDetails.paymentMode==="Online"){
            if(incomeDetails.referenceId===""){
                enqueueSnackbar("Enter Online Transaction Number!",{variant : 'info'});
                validateResult="FAIL";
            }else if(incomeDetails.bankName===""){
                enqueueSnackbar("Enter Bank Name!",{variant : 'info'});
                validateResult="FAIL";
            }
        }else if(incomeDetails.incomeDate===""){
            enqueueSnackbar("Payment Date cannot be null!",{variant : 'info'});
            validateResult="FAIL";
        }else if(incomeDetails.incomeCategory===""){
            enqueueSnackbar("Enter Valid income Category!",{variant : 'info'});
            validateResult="FAIL";
        }else if(incomeDetails.incomeSubCategory===""){
            enqueueSnackbar("Enter Valid income Sub Category!",{variant : 'info'});
            validateResult="FAIL";
        }else if(incomeDetails.incomeAmount<1){
            enqueueSnackbar("Enter valid Amount!",{variant : 'info'});
            validateResult="FAIL";
        }else{
            validateResult="PASS";
        }
        return validateResult;
    }

    const handleAddIncome=async(event)=>{
        event.preventDefault();
        try{            
            const validateResult=validateFields();
            //console.log("validation : ", validateResult);
            if(validateResult==="PASS"){
            const orderNo=generateOrderNo();
            const todayDate=generateDate(0,0,0);
            const {incomeDate, incomeAmount, incomeCategory, incomeSubCategory, paymentMode}=incomeDetails;
            const params={
                clientID:loggedUser.nickname,
                incomeID:orderNo,
                incomeDate:incomeDate,
                incomeAmount:incomeAmount,
                incomeCategory:incomeCategory,
                incomeSubCategory:incomeSubCategory,
                paymentMode:paymentMode,
                updatedBy:loggedUser.name,
                updatedOn:todayDate
            };
            console.log("Exp Details:", params);
            await axios.post(`${config.api.invokeUrl}/tracker/income`, params,{
                headers: {
                //  Authorization: idToken,
                  'x-api-key':config.api.key
                }})
                .then(async(response)=>{
                 enqueueSnackbar("Payment successfully processed!",{variant : 'success'});
                 assignDefaultValues();
                window.scrollTo(0,0); 
                setLoaded(true);
                //newToggle();
                }).catch((error)=>{
                    enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
                  window.scrollTo(0,0); 
                  setLoaded(true);
                })
        }
        }catch(error){
            enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
        }
    }

    const handleUpdateIncome=(event)=>{
        event.preventDefault();
        
    }

    const handleEventKey=(event, item)=>{
        //console.log("event : ", event);
        if(event.key === 'Enter'){
            if(item==="1"){
                inputEndDate.current.focus();
            }else if(item==="2"){
                inputPaymentMode.current.focus();
            }else if(item==="3"){
                inputCurrency.current.focus();
            }else if(item==="4"){
                inputYears.current.focus();
            }else if(item==="5"){
                inputOrderAmount.current.focus();
            }else if(item==="8"){
                inputTransactionDate.current.focus();
            }else if(item==="9"){
                inputComments.current.focus();
            }
        }
    }

    const handleReoccurClick=(event)=>{
       // event.preventDefault();
        setReoccuringEvent(!reoccuringEvent);
    }

  return (
    <div>
    <Helmet>
      <title>Registration - Admin Office - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION</title>
      <meta name="description" content="Registration - Admin Office - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION" />
      <meta name="keywords" content="Registration - Admin Office - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION" /> 
    </Helmet>

        <Preloader show={loaded ? false : true} />
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><i className="fa fa-home" aria-hidden="true"></i></Breadcrumb.Item>
            <Breadcrumb.Item>Income</Breadcrumb.Item>
            <Breadcrumb.Item active>New</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        {/*<div className="btn-toolbar mb-2 mb-md-0"> 
          <ButtonGroup>
          <ReactToPrint
        content={reactToPrintContent}
        documentTitle="student"
        trigger={reactToPrintTrigger}
      />
          </ButtonGroup>
        </div>*/}
        </div>
        <div className="table-settings mb-4">   
    <div>
    <Card>
    <CardBody>
    <Row>
    <Col md={6} className="mb-3">
    <Form.Label>New Income Details :  </Form.Label>
    <Form.Check // prettier-ignore
        type="switch"
        id="custom-switch"
        label="Re-occuring event"
        onChange={event=>handleReoccurClick(event)}
      />
    </Col>

    <Col md={6} className="mb-3">
    <Form.Label>Income Date:  </Form.Label>
     <InputGroup>
          <InputGroup.Text>Income Date
          </InputGroup.Text>
          <Form.Control type="date" autoFocus value={incomeDetails.incomeDate} disabled={viewOnly}
          onChange={event=>setIncomeDetails({...incomeDetails, incomeDate:event.target.value})}
          ref={inputStartDate} onKeyDown={event=>{handleEventKey(event,"1")}} />
        </InputGroup>
        </Col>
    </Row>
    {reoccuringEvent?<Row>
        <Col md={6} className="mb-3">
    <Form.Label>Occuring Pattern:  </Form.Label>
    <Form.Group id="cateogory">
           
    <Form.Select defaultValue="INR" value={incomeDetails.occuringPattern} disabled={viewOnly}
           onChange={(event)=>{setIncomeDetails({...incomeDetails, occuringPattern: event.target.value})}}
           ref={inputCurrency} onKeyDown={event=>{handleEventKey(event,"4")}} >
             <option value="Weekly">Weekly</option>
             <option value="Monthly">Monthly</option>
             <option value="Yearly">Yearly</option>
           </Form.Select>
         </Form.Group>
        </Col>
    <Col md={6} className="mb-3">
    <Form.Label>Start Date:  </Form.Label>
    <Form.Group id="searchstud">
    <Form.Control type="date" autoFocus value={incomeDetails.occuringDate} disabled={viewOnly}
          ref={inputStartDate} onKeyDown={event=>{handleEventKey(event,"1")}} 
          onChange={(event)=>{setIncomeDetails({...incomeDetails, occuringDate: event.target.value})}}
    />
         </Form.Group>
        </Col>
    </Row> :null}

<Row>
        <Col md={6} className="mb-3">
    <Form.Label>Category:  </Form.Label>
    <Form.Group id="cateogory">
           
    <Form.Select defaultValue="INR" value={incomeDetails.incomeCategory} disabled={viewOnly}
           onChange={(event)=>{setIncomeDetails({...incomeDetails, incomeCategory: event.target.value})}}
           ref={inputCurrency} onKeyDown={event=>{handleEventKey(event,"4")}} >
             {incomeCategory.map((item,index)=>
             <option value={item.categoryName} key={index}>{item.categoryName}</option>)}
           </Form.Select>
         </Form.Group>
        </Col>
    <Col md={6} className="mb-3">
    <Form.Label>Sub Category:  </Form.Label>
    <Form.Group id="searchstud">
    <Form.Control type="text" autoFocus value={incomeDetails.incomeSubCategory} disabled={viewOnly}
          ref={inputStartDate} onKeyDown={event=>{handleEventKey(event,"1")}} 
          onChange={(event)=>{setIncomeDetails({...incomeDetails, incomeSubCategory: event.target.value})}}
    />
         </Form.Group>
        </Col>
    
    </Row> 
    <Row>
    <Col md={6} className="mb-3">
    <Form.Label>Amount Paid :  </Form.Label>
    <InputGroup>
    <Form.Control type="number" value={incomeDetails.incomeAmount} disabled={viewOnly} 
    onKeyDown={event=>{handleEventKey(event,"8")}} ref={inputOrderAmount}
    onChange={(event)=>{setIncomeDetails({...incomeDetails, incomeAmount: event.target.value})}}
    />
    </InputGroup>
    </Col>
    <Col md={6} className="mb-3">
    <Form.Label>Mode of Payment:  </Form.Label>
     <Form.Group id="searchstud">
           <Form.Select defaultValue="Cash" value={incomeDetails.paymentMode} disabled={viewOnly}
           onChange={(event)=>{setIncomeDetails({...incomeDetails, paymentMode: event.target.value})}}
           ref={inputPaymentMode} onKeyDown={event=>{handleEventKey(event,"3")}} >
             <option value="Cash">Cash</option>
             <option value="UPI">UPI</option>
             <option value="Cheque">Cheque</option>
             <option value="Bank Transfer">Bank Transfer</option>
             <option value="Demand Draft">Demand Draft</option>
             <option value="Money Order">Money Order</option>
             <option value="Online">Online</option>
           </Form.Select>
         </Form.Group>
        </Col>
    </Row>
    {incomeDetails.paymentMode==="Cash"?null: <Row>
    <Col md={6} className="mb-3">
    <Form.Label>{incomeDetails.paymentMode} No:  </Form.Label>
     <InputGroup>
          <InputGroup.Text>
          </InputGroup.Text>
          <Form.Control type="text" placeholder="Cheque / DD / Transaction No" value={incomeDetails.referenceId} 
          disabled={viewOnly} ref={inputReferenceID} onKeyDown={event=>{handleEventKey(event,"6")}}
          onChange={(event)=>{setIncomeDetails({...incomeDetails, referenceId: event.target.value})}}/>
        </InputGroup>
    </Col>
    <Col md={6} >
      <Form.Label>Bank Name:  </Form.Label>
    
      <InputGroup>
          <InputGroup.Text>
          </InputGroup.Text>
          <Form.Control type="text" placeholder="Bank Name" value={incomeDetails.bankName} disabled={viewOnly}
            ref={inputBankName} onKeyDown={event=>{handleEventKey(event,"7")}}
          onChange={(event)=>{setIncomeDetails({...incomeDetails, bankName: event.target.value})}}/>
        </InputGroup>
      </Col>
    </Row>}
    <Row>
    <Form.Label>Comments:  </Form.Label>
    
    <InputGroup>
        <InputGroup.Text>
        </InputGroup.Text>
        <Form.Control type="text" placeholder="Comments..." value={incomeDetails.comments} disabled={viewOnly}
        onChange={(event)=>{setIncomeDetails({...incomeDetails, comments: event.target.value})}}
        ref={inputComments} onKeyDown={event=>{handleEventKey(event,"10")}} />
      </InputGroup>

    </Row>
            </CardBody>
        </Card>
    </div>
    
    <Row >
    {formType==="New"?<div className="mb-2 mb-md-0 pt-3 d-flex justify-content-evenly">
    <Button variant="primary" type="submit" onClick={(event)=>{handleAddIncome(event)}}>Save</Button>
    <Button variant="secondary" type="submit" onClick={event=>props.handleCloseNewExp(event)}>Cancel</Button>
      </div>:formType==="Edit"?<div className="text-center mb-2 mb-md-0">
    <Button variant="primary" type="submit" onClick={(event)=>{handleUpdateIncome(event)}}>Update</Button>
    <Button variant="secondary" type="submit" onClick={event=>props.handleCloseNewExp(event)}>Cancel</Button>
    </div>:null}
    </Row>
  </div>
</div>
  )
}
