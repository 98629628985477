import React, { useEffect, useState, useContext, useRef } from "react";
import { Col, Row, Form, Button, InputGroup, Card, CardBody } from 'react-bootstrap';
import ReactToPrint from "react-to-print";
import { ButtonGroup, Breadcrumb } from 'react-bootstrap';
import { ProductContext } from '../../ProductProvider';
import Preloader from "../../components/Preloader/Preloader";
import { fetchAuthSession } from 'aws-amplify/auth';
import { expenseCategory, journalCategory } from "../../ProData";
import axios from "axios";
import {Helmet} from 'react-helmet-async';
import { useSnackbar } from 'notistack';

const formType="New";
const config = require('../../config.json');

export default function ExpenseForm(props) {
    const {loggedUser, generateOrderNo, generateDate, expenseDetails, setExpenseDetails} = useContext(ProductContext);
    const [loaded, setLoaded] = useState(true);
    const [viewOnly, setViewOnly] = useState(true);
    const inputCategory=useRef(null);
    const inputPaymentMode=useRef(null);
    const inputSubCategory=useRef(null);
    const inputStartDate=useRef(null);
    const inputOrderAmount=useRef(null);
    const inputComments=useRef(null);
    const inputReferenceID=useRef(null);
    const inputBankName=useRef(null);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(()=>{
        //console.log("Props : ", props);
        if(formType==="New"){
            //resetValues();
           // assignNewSubcriptionDates();
            assignDefaultValues();
            setViewOnly(false);
        }else if (formType==="View"){
            setViewOnly(true);
           // assignValues();
        }else if(formType==="Edit"){
            setViewOnly(false);
           // assignValues();
        }
    },[])

    const assignDefaultValues=()=>{
        const todayDate=generateDate(0,0,0);
        setExpenseDetails({...expenseDetails,
            expenseDate:todayDate,
            paymentMode:'Cash',
            expenseCategory:expenseCategory[0].categoryName,
        });
    }

    const validateFields=()=>{
        var validateResult="PASS";
        if(expenseDetails.paymentMode==="Cheque"){
            if(expenseDetails.referenceId===""){
                enqueueSnackbar("Enter Cheque Number!",{variant : 'info'});
                validateResult="FAIL";
            }else if(expenseDetails.bankName===""){
                enqueueSnackbar("Enter Bank Name!",{variant : 'info'});
                validateResult="FAIL";
            }
        } else if(expenseDetails.paymentMode==="DD"){
            if(expenseDetails.referenceId===""){
                enqueueSnackbar("Enter DD Number!",{variant : 'info'});
                validateResult="FAIL";
            }else if(expenseDetails.bankName===""){
                enqueueSnackbar("Enter Bank Name!",{variant : 'info'});
                validateResult="FAIL";
            }
        }else if(expenseDetails.paymentMode==="Online"){
            if(expenseDetails.referenceId===""){
                enqueueSnackbar("Enter Online Transaction Number!",{variant : 'info'});
                validateResult="FAIL";
            }else if(expenseDetails.bankName===""){
                enqueueSnackbar("Enter Bank Name!",{variant : 'info'});
                validateResult="FAIL";
            }
        }else if(expenseDetails.expenseDate===""){
            enqueueSnackbar("Payment Date cannot be null!",{variant : 'info'});
            validateResult="FAIL";
        }else if(expenseDetails.expenseCategory===""){
            enqueueSnackbar("Enter Valid Expense Category!",{variant : 'info'});
            validateResult="FAIL";
        }else if(expenseDetails.expenseSubCategory===""){
            enqueueSnackbar("Enter Valid Expense Sub Category!",{variant : 'info'});
            validateResult="FAIL";
        }else if(expenseDetails.expenseAmount<1){
            enqueueSnackbar("Enter valid Amount!",{variant : 'info'});
            validateResult="FAIL";
        }else{
            validateResult="PASS";
        }
        return validateResult;
    }

    const handleAddExpense=async(event)=>{
        event.preventDefault();
        try{            
            const validateResult=validateFields();
            //console.log("validation : ", validateResult);
            if(validateResult==="PASS"){
            const orderNo=generateOrderNo();
            const todayDate=generateDate(0,0,0);
            const {expenseDate, expenseAmount, expenseCategory, expenseSubCategory, paymentMode,referenceId,bankName, comments}=expenseDetails;
            const params={
                clientID:loggedUser.nickname,
                expenseID:orderNo,
                expenseDate:expenseDate,
                expenseAmount:expenseAmount,
                expenseCategory:expenseCategory,
                expenseSubCategory:expenseSubCategory,
                paymentMode:paymentMode,
                updatedBy:loggedUser.name,
                updatedOn:todayDate,
                bankName:bankName,
                referenceId:referenceId,
                comments:comments
            };
            console.log("Exp Details:", params);
            await axios.post(`${config.api.invokeUrl}/tracker/expense`, params,{
                headers: {
                //  Authorization: idToken,
                  'x-api-key':config.api.key
                }})
                .then(async(response)=>{
                 enqueueSnackbar("Expense Added successfully!",{variant : 'success'});
                 assignDefaultValues();
                window.scrollTo(0,0); 
                setLoaded(true);
                //newToggle();
                }).catch((error)=>{
                    enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
                  window.scrollTo(0,0); 
                  setLoaded(true);
                })
        }
        }catch(error){
            enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
        }
    }

    const handleUpdateExpense=(event)=>{
        event.preventDefault();
        
    }

    const handleEventKey=(event, item)=>{
        //console.log("event : ", event);
        if(event.key === 'Enter'){
            if(item==="1"){
                inputCategory.current.focus();
            }else if(item==="2"){
                inputSubCategory.current.focus();
            }else if(item==="3"){
                inputOrderAmount.current.focus();
            }else if(item==="4"){
                inputPaymentMode.current.focus();
            }else if(item==="5"){
                inputComments.current.focus();
            }else if(item==="6"){
                inputReferenceID.current.focus();
            }else if(item==="9"){
                inputBankName.current.focus();
            }
        }
    }

  return (
    <div>
    <Helmet>
      <title>Registration - Admin Office - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION</title>
      <meta name="description" content="Registration - Admin Office - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION" />
      <meta name="keywords" content="Registration - Admin Office - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION" /> 
    </Helmet>

        <Preloader show={loaded ? false : true} />
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><i className="fa fa-home" aria-hidden="true"></i></Breadcrumb.Item>
            <Breadcrumb.Item>Expenses</Breadcrumb.Item>
            <Breadcrumb.Item active>New</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        {/*<div className="btn-toolbar mb-2 mb-md-0"> 
          <ButtonGroup>
          <ReactToPrint
        content={reactToPrintContent}
        documentTitle="student"
        trigger={reactToPrintTrigger}
      />
          </ButtonGroup>
        </div>*/}
        </div>
        <div className="table-settings mb-4">   
    <div>
    <Card>
    <CardBody>
    <Row>
    <Col md={6} className="mb-3">
    <Form.Label>New Expense Details :  </Form.Label>
    </Col>

    <Col md={6} className="mb-3">
     <InputGroup>
          <InputGroup.Text>
          </InputGroup.Text>
          <Form.Control type="date" autoFocus value={expenseDetails.expenseDate} disabled={viewOnly}
          onChange={(event)=>{setExpenseDetails({...expenseDetails, expenseDate: event.target.value})}}
          ref={inputStartDate} onKeyDown={event=>{handleEventKey(event,"1")}} />
        </InputGroup>
        </Col>
    </Row>
<Row>
        <Col md={6} className="mb-3">
    <Form.Label>Category:  </Form.Label>
    <Form.Group id="cateogory">
           
    <Form.Select value={expenseDetails.expenseCategory} disabled={viewOnly}
           onChange={(event)=>{setExpenseDetails({...expenseDetails, expenseCategory: event.target.value})}}
           ref={inputCategory} onKeyDown={event=>{handleEventKey(event,"2")}} >
             {expenseCategory.map((item,index)=>
             <option value={item.categoryName} key={index}>{item.categoryName}</option>)}
           </Form.Select>
         </Form.Group>
        </Col>
    <Col md={6} className="mb-3">
    <Form.Label>Sub Category:  </Form.Label>
    <Form.Group id="searchstud">
    <Form.Control type="text" autoFocus value={expenseDetails.expenseSubCategory} disabled={viewOnly}
          ref={inputSubCategory} onKeyDown={event=>{handleEventKey(event,"3")}} 
          onChange={(event)=>{setExpenseDetails({...expenseDetails, expenseSubCategory: event.target.value})}}
    />
         </Form.Group>
        </Col>
    
    </Row> 
    <Row>
    <Col md={6} className="mb-3">
    <Form.Label>Amount Paid :  </Form.Label>
    <InputGroup>
    <Form.Control type="number" value={expenseDetails.expenseAmount} disabled={viewOnly} 
    onKeyDown={event=>{handleEventKey(event,"4")}} ref={inputOrderAmount}
    onChange={(event)=>{setExpenseDetails({...expenseDetails, expenseAmount: event.target.value})}}
    />
    </InputGroup>
    </Col>
    <Col md={6} className="mb-3">
    <Form.Label>Mode of Payment:  </Form.Label>
     <Form.Group id="searchstud">
           <Form.Select defaultValue="Cash" value={expenseDetails.paymentMode} disabled={viewOnly}
           onChange={(event)=>{setExpenseDetails({...expenseDetails, paymentMode: event.target.value})}}
           ref={inputPaymentMode} onKeyDown={event=>{handleEventKey(event,"5")}} >
             <option value="Cash">Cash</option>
             <option value="UPI">UPI</option>
             <option value="Cheque">Cheque</option>
             <option value="Bank Transfer">Bank Transfer</option>
             <option value="Demand Draft">Demand Draft</option>
             <option value="Money Order">Money Order</option>
             <option value="Online">Online</option>
           </Form.Select>
         </Form.Group>
        </Col>
    </Row>
    {expenseDetails.paymentMode==="Cash"?null: <Row>
    <Col md={6} className="mb-3">
    <Form.Label>{expenseDetails.paymentMode} No:  </Form.Label>
     <InputGroup>
          <InputGroup.Text>
          </InputGroup.Text>
          <Form.Control type="text" placeholder="Cheque / DD / Transaction No" value={expenseDetails.referenceId} 
          disabled={viewOnly} ref={inputReferenceID} onKeyDown={event=>{handleEventKey(event,"7")}}
          onChange={(event)=>{setExpenseDetails({...expenseDetails, referenceId: event.target.value})}}/>
        </InputGroup>
    </Col>
    <Col md={6} >
      <Form.Label>Bank Name:  </Form.Label>
    
      <InputGroup>
          <InputGroup.Text>
          </InputGroup.Text>
          <Form.Control type="text" placeholder="Bank Name" value={expenseDetails.bankName} disabled={viewOnly}
            ref={inputBankName} onKeyDown={event=>{handleEventKey(event,"8")}}
          onChange={(event)=>{setExpenseDetails({...expenseDetails, bankName: event.target.value})}}/>
        </InputGroup>
      </Col>
    </Row>}
    <Row>
    <Form.Label>Comments:  </Form.Label>
    
    <InputGroup>
        <InputGroup.Text>
        </InputGroup.Text>
        <Form.Control type="text" placeholder="Comments..." value={expenseDetails.comments} disabled={viewOnly}
        onChange={(event)=>{setExpenseDetails({...expenseDetails, comments: event.target.value})}}
        ref={inputComments} onKeyDown={event=>{handleEventKey(event,"6")}} />
      </InputGroup>

    </Row>
    </CardBody>
    </Card>
    </div>
    
   
   {/*} <Row>
    <Col md={6} className="mb-3">
    <Form.Check type="checkbox">
        <FormCheck.Input id="defaultCheck5" className="me-2" checked={concessionEnabled} onChange={(event)=>{handleConcessionCheck(event)}}/>
        <FormCheck.Label htmlFor="defaultCheck5" className="mb-0">Process Concession</FormCheck.Label>
    </Form.Check>
    </Col>
    {concessionEnabled?
    <Col md={6} className="mb-3">
    <Form.Label>Concession Amount :  </Form.Label>
    <InputGroup>
        <Form.Control type="number" value={concessionValue} onChange={(event)=>{handleConcessionUpdate(event)}} />
    </InputGroup>
    </Col>:null}
    </Row>*/}
    {/*<Row>
    <Col md={6} className="mb-3">
    <Form.Check type="checkbox">
        <FormCheck.Input id="donationCheck5" className="me-2" checked={donationEnabled} onChange={(event)=>{handleDonationCheck(event)}}/>
        <FormCheck.Label htmlFor="defaultCheck5" className="mb-0">Process Donation</FormCheck.Label>
    </Form.Check>
    </Col>
    {donationEnabled?
    <Col md={6} className="mb-3">
    <Form.Label>Donation Amount :  </Form.Label>
    <InputGroup>
        <Form.Control type="number" value={donationValue} onChange={(event)=>{handleDonationUpdate(event)}} />
    </InputGroup>
    </Col>:null}
    </Row>*/}
    <Row >
    {formType==="New"?<div className="mb-2 mb-md-0 pt-3 d-flex justify-content-evenly">
    <Button variant="primary" type="submit" onClick={(event)=>{handleAddExpense(event)}}>Save</Button>
    <Button variant="secondary" type="submit" onClick={event=>props.handleCloseNewExp(event)}>Cancel</Button>
      </div>:formType==="Edit"?<div className="text-center mb-2 mb-md-0">
    <Button variant="primary" type="submit" onClick={(event)=>{handleUpdateExpense(event)}}>Update</Button>
    <Button variant="secondary" type="submit" onClick={event=>props.handleCloseNewExp(event)}>Cancel</Button>
    </div>:null}
    </Row>
  </div>
</div>
  )
}
