import React from 'react';
import './AddExpButton.css';

export default function AddExpButton(props) {
    const {handleShowNewExp} = props;
  return (
    <div>
        <a href="#" className="float" onClick={event=>handleShowNewExp(event)}>
<i className="fa fa-plus my-float"></i>
</a>
    </div>
  )
}
