import React, { useState, useContext, useEffect } from "react";
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown, Table } from 'react-bootstrap';
import {Modal, ModalBody, ModalHeader, ModalFooter  } from "react-bootstrap";
import Preloader from "../../components/Preloader/Preloader";
import { ProductContext } from '../../ProductProvider';
import axios from "axios";
import ReactToPrint from "react-to-print";
import { fetchAuthSession } from 'aws-amplify/auth';
import { CSVLink } from "react-csv";
import { downloadPaymentHeaders, journalCategory } from "../../ProData";
import {Helmet} from 'react-helmet-async';
//import SubscriberForm from "./SubscriberForm";
import PaymentForm from "../payment/PaymentForm";
import { useSnackbar } from 'notistack';

const config = require('../../config.json');

export default function PaymentSearch() {
  const { loggedUser, validateLogin, setsubscriberDetails} = useContext(ProductContext);
  const csvLinkEl = React.createRef();
  //const [applicationDetails, setApplicationDetails]=useState([]);
  const [searchTopic, setSearchTopic] = useState('SubscriberID');
  const [searchValue, setSearchValue] = useState('');
  const [loaded, setLoaded] = useState(true);
  const [searchResults, setSearchResults] = useState([]);
  //const [collapsed, setCollapsed] = useState(true);
  const [newCollapsed, setNewCollapsed] = useState(true);
  const [selectedItem, setSelectedItem] = useState({});
  const [action, setAction] = useState('View');
  const componentRef = React.useRef(null);
  const componentModelRef = React.useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(()=>{
    if(loggedUser.isAuthenticated){
      //processBatchYear();
    }else{
    validateLogin();
    }
  },[loggedUser])

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const reactToPrintTrigger = React.useCallback(() => {
    return <Button variant="outline-primary"  size="sm">Print</Button>;
  }, []);

  const reactToPrintContentModal = React.useCallback(() => {
    return componentModelRef.current;
  }, [componentModelRef.current]);

  const reactToPrintTriggerModal = React.useCallback(() => {
    return <Button   >Print</Button>;
  }, []);

  const handleApplicationView=(event, item, taction)=>{
    event.preventDefault();
    try{
      setAction(taction);
      setSelectedItem(item);
      setsubscriberDetails(item);
      newToggle();
    }catch(error){
      enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
    }
  }

  const handleDownload =async(event)=>{
    event.preventDefault();
    csvLinkEl.current.link.click();
  }

 /* const toggle = () => {
    //   console.log("clicked");
         setCollapsed(!collapsed);
  }*/

  const newToggle = () => {
  //   console.log("clicked");
        setNewCollapsed(!newCollapsed);
  }

  const handleSearch=async()=>{
    //event.preventDefault();
    try{
      if(searchValue===""){
        enqueueSnackbar("Search Value is empty Enter the search input and then hit Search button!",{variant : 'info'});
      }else{
        setLoaded(false);
        const { idToken } = (await fetchAuthSession()).tokens ?? {};
      await axios.get(`${config.api.invokeUrl}/payments/search?fetchKey=${searchValue}&fetchOption=${searchTopic}`, {
        headers: {
          Authorization: idToken,
          'x-api-key':config.api.key
        }
      }).then(async(response) => {
        //console.log("response : ", response.data);
        if(response.data.length>0)
        {
            //console.log("order status")
            setSearchResults(response.data);
        }
        enqueueSnackbar(response.data.length + " - Payment records found!",{variant : 'success'});
        setLoaded(true);

    }).catch((error)=>{
      enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
    setLoaded(true);
  })
}
    }catch(error){
      enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
    setLoaded(true);
    }
  }


  return (
<>
    <Helmet>
      <title>Payment Search - Admin Office - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION</title>
      <meta name="description" content="Payment Search - Admin Office - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION" />
      <meta name="keywords" content="Payment Search - Admin Office - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION" /> 
    </Helmet>

        <Preloader show={loaded ? false : true} />
        {/*Payment*/}
        <Modal toggle={newToggle} isOpen={!newCollapsed} style={{top:'10%', maxWidth:'800px'}}>
            <ModalHeader toggle={newToggle}>
            Payment - {action}
            </ModalHeader>
            <ModalBody>    
            <div>
            <PaymentForm formType={action} newToggle={newToggle} selectedItem={selectedItem}/> 
              </div>
            </ModalBody>
            <ModalFooter>
            {' '}
            <Button onClick={newToggle}>
                Close
            </Button>
            </ModalFooter>
          </Modal>


      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><i className="fa fa-home" aria-hidden="true"></i>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Payments</Breadcrumb.Item>
            <Breadcrumb.Item active>Report</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Payments Report</h4>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0"> 
          <ButtonGroup>
          <ReactToPrint
        content={reactToPrintContent}
        documentTitle="student"
        trigger={reactToPrintTrigger}
      />
          <Button variant="outline-primary" size="sm" onClick={(event)=>{handleDownload(event)}}>Download</Button>
          <CSVLink
              headers={downloadPaymentHeaders}
              filename="download.csv"
              data={searchResults}
              ref={csvLinkEl}
            />
          </ButtonGroup>
        </div>
      </div>
      <div className="table-settings mb-4">
        <Row className="justify-content-start align-items-center">
        <Col md={4} className="mb-3">
              <Form.Group id="searchstud">               
                <Form.Select defaultValue="SubscriberID" value={searchTopic} onChange={(event)=>{setSearchTopic(event.target.value)}}>
                <option value="SubscriberID">Subscriber ID</option>
                <option value="PaymentID">Payment ID</option>
                <option value="TransactionDate">Transaction Date</option>
                </Form.Select>
              </Form.Group>
            </Col>
          <Col  className="mb-3" md={8}>
            <InputGroup>
              <InputGroup.Text>
              <i className="fa fa-search" aria-hidden="true"></i>
              </InputGroup.Text>
              {searchTopic==="Category"?           
              <Form.Select value={searchValue} 
                  onChange={(event)=>{setSearchValue(event.target.value)}}>
                  {journalCategory.map(item => {
                    return (
                    <option key={item} value={item}>{item}</option>
                    );
                  })}
            </Form.Select>:
            <Form.Control type="text" placeholder="Search" value={searchValue} onChange={(event)=>{setSearchValue(event.target.value)}}/>}
              <Button variant="primary" type="submit" onClick={(event)=>{handleSearch()}}>Submit</Button>
            </InputGroup>
          </Col>
        </Row>
      </div>

      <Table ref={componentRef} hover responsive>
             <thead>
              <tr>
                <th>#</th>
                <th>Payment ID</th>
                <th>Subscriber ID</th>
                <th>Transaction Date</th>
                <th>Amount</th>
                <th>Payment Mode</th>
                <th>Status</th>
               <th>Action</th>
              </tr>
            </thead>
            <tbody>
            {searchResults.map((item, index)=>
            <tr key={index}>
            <th scope="row">{index+1}</th>
            <td>{item.paymentID}</td>
            <td>{item.subscriberID}</td>
            <td>{item.transactionDate}</td>
            <td>{item.orderAmount}</td>
            <td>{item.paymentMode}</td>
            <td>{item.txStatus==="TXN_FAILURE"?<p style={{backgroundColor:"blue", borderRadius:"10px", textAlign:"center", color:"white"}}>Failed</p>:
            item.txStatus==="TXN_SUCCESS"?<p style={{backgroundColor:"green", borderRadius:"10px", textAlign:"center", color:"white"}}>Success</p>:
            <p style={{backgroundColor:"red", borderRadius:"10px", textAlign:"center", color:"white"}}>Cancelled</p>}</td>
           <td>
           <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
              <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item  onClick={(event)=>{handleApplicationView(event, item, "View")}}>
              <i className="fa fa-eye" aria-hidden="true"></i> View Details
              </Dropdown.Item>
              <Dropdown.Item  onClick={(event)=>{handleApplicationView(event, item, "Edit")}}>
              <i className="fa fa-pencil" aria-hidden="true"></i>Edit Details
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
            </td>
            </tr>)}
            </tbody>
      </Table>
    </>
  )
}
