import React, {useState} from 'react';
import axios from "axios";
import { fetchAuthSession } from 'aws-amplify/auth';
import { RoutesLocal } from './routes';
import { useNavigate } from "react-router-dom";

//import { withRouter, useHistory} from "react-router-dom";
//import { courseMaterialData } from './ProData';
const ProductContext = React.createContext();;
const config = require('./config.json');

const ProductProvider = (props) => {
  const navigate = useNavigate();
  const [loggedUser, setLoggedUser] = useState({
    name: 'Guest', email: '', website:'', profile:'Guest', DOB:'', isAuthenticated:false, mobile:'', nickname:'',gender:'',family_name:'',phone_number:'',});
const [alertDetails, setAlertDetails] = useState({style:"", open:false, message:""});
const [myProfileImage, setMyProfileImage] = useState('defaultAvatar.jpg');
const [expenseDetails, setExpenseDetails] = useState({expenseID:'', expenseDate:'', expenseAmount:'', 
  expenseCategory:'Cash', expenseSubCategory:'', paymentMode:'Cash', bankName:'NA', referenceId:'NA', comments:''});
const [incomeDetails, setIncomeDetails] = useState({incomeID:'', incomeDate:'', incomeAmount:'', 
  incomeCategory:'Cash', incomeSubCategory:'', paymentMode:'Cash'});
  
//const [courses, setCourses]= useState(coursesOffered);
const [isSidebarOpen, setIsSidebarOpen]= useState(false);
const [loginValidated, setLoginValidated] = useState(false);

  const handleImageError=(e)=>{
    e.target.src = "https://s3.ap-south-1.amazonaws.com/dev.idntica.com/img/gallery/defaultAvatar.jpg"
  }

  const generateDate=(yy,mm,dd)=>{
    const current_datetime = new Date();
    var tempUserId="";
    const date_year = (current_datetime.getFullYear()+yy).toString();
    var date_month = (current_datetime.getMonth() + 1+mm).toString();
    var month_value="";
    date_month.length===1? (month_value=month_value.concat(0,date_month)):(month_value=date_month);
    var date_date = ((current_datetime.getDate()+dd).toString());
    var date_value="";
    date_date.length===1?(date_value=date_value.toString().concat(0,date_date)):(date_value=date_date);
    var date_hours = (current_datetime.getHours().toString());
    var hours_value="";
    date_hours.length===1?(hours_value=hours_value.toString().concat(0,date_hours)):(hours_value=date_hours);
    var date_minutes = (current_datetime.getMinutes().toString());
    var minutes_value="";
    date_minutes.length===1 ?(minutes_value=minutes_value.toString().concat(0,date_minutes)):(minutes_value=date_minutes);
    const accountNo = tempUserId.concat(date_year,"-",month_value,"-", date_value);
    //setUserId(accountNo);
    //setMemberInfo({...memberInfo, userId:accountNo});
    return accountNo;
    //console.log("acc : ", accountNo);
  
  }

  const generateOrderNo=()=>{
    const current_datetime = new Date();
    var tempUserId="";
    const date_year = (current_datetime.getFullYear()).toString();
    var date_month = (current_datetime.getMonth() + 1).toString();
    var month_value="";
    date_month.length===1? (month_value=month_value.concat(0,date_month)):(month_value=date_month);
    var date_date = (current_datetime.getDate().toString());
    var date_value="";
    date_date.length===1?(date_value=date_value.toString().concat(0,date_date)):(date_value=date_date);
    var date_hours = (current_datetime.getHours().toString());
    var hours_value="";
    date_hours.length===1?(hours_value=hours_value.toString().concat(0,date_hours)):(hours_value=date_hours);
    var date_minutes = (current_datetime.getMinutes().toString());
    var minutes_value="";
    date_minutes.length===1 ?(minutes_value=minutes_value.toString().concat(0,date_minutes)):(minutes_value=date_minutes);
    const accountNo = tempUserId.concat(date_year,month_value, date_value, hours_value, minutes_value);
    //setUserId(accountNo);
    //setMemberInfo({...memberInfo, userId:accountNo});
    return accountNo;
    //console.log("acc : ", accountNo);
  
  }

const validateLogin = async() =>{
    try{
      await fetchAuthSession()
      .then(async(user)=>{
        console.log("user : ", user);
        var tadmin=false;
        const {email, profile, name, nickname,family_name, birthdate, phone_number, gender, website, picture }= user.tokens.idToken.payload;
        if(profile==="Admin"||profile==="Owner"){
          //console.log("Profile :", profile);
         tadmin=true;
         await setLoggedUser({...loggedUser,
          name:name,
          email:email,
          profile:profile,
          DOB:birthdate,
          website:website,
          nickname:nickname,
          gender:gender,
          family_name:family_name,
          phone_number:phone_number,
          isAuthenticated:true,
          isAdmin:tadmin
        });
        const tnickname=family_name;
        const tImageName=family_name+".jpeg";
        setMyProfileImage(`${config.s3.profileUrl}${tnickname}${"/"}${tImageName}`);
        navigate(RoutesLocal.Presentation.path);
        //console.log("profile image : ", myProfileImage);
        // fetchOpenItems();
        }else{
          console.log("not authorized");
          setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"You are not authorized to access this portal"
        });         
      //  return "NOT Authorized";
          navigate(RoutesLocal.Signin.path);
         }        
      })
      .catch((error) =>{
        console.log("error:", error);
        setLoggedUser({...loggedUser,
          name:'',
          email:'',
          DOB:'',
          profile:'',
          website:'',
          nickname:'',
          gender:'',
          family_name:'',
          phone_number:'',
          isAuthenticated:false,
          isAdmin:false
        }); 
        navigate(RoutesLocal.Signin.path);
      })     
    }catch(error){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured : " + error.message
    });    
    navigate(RoutesLocal.Signin.path);
  }
  }

    return (
        <ProductContext.Provider value={{
            loggedUser,
            setLoggedUser,
            isSidebarOpen,
            setIsSidebarOpen,
            handleImageError, 
            generateDate,
            myProfileImage,
            setMyProfileImage,
            generateOrderNo,
            validateLogin,
            loginValidated, 
            setLoginValidated,
            expenseDetails, 
            setExpenseDetails,
            incomeDetails, 
            setIncomeDetails
        }}>
        {props.children}
        </ProductContext.Provider>
    )
}
export {ProductProvider, ProductContext};
